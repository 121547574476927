<template>
<div>
  <v-container
    fill-height
    fluid
    grid-list-xl
  >
  <v-layout wrap row xs12>
    <v-menu ref="dateFrom"
            lazy
            :close-on-content-click="false"
            v-model="dateFrom"
            transition="scale-transition"
            offset-y
            full-width
            :nudge-right="40"
            min-width="290px"
            dark
            :return-value.sync="from">
        <v-text-field slot="activator"
                      label="С: "
                      v-model="from"
                      prepend-icon="mdi-calendar"
                      single-line
                      readonly></v-text-field>
        <v-date-picker locale="ru-ru" :first-day-of-week="1" v-model="from" @input="$refs.dateFrom.save(from)"></v-date-picker>
    </v-menu>
    <v-menu ref="dateTo"
            lazy
            :close-on-content-click="false"
            v-model="dateTo"
            transition="scale-transition"
            offset-y
            full-width
            :nudge-right="40"
            min-width="290px"
            dark
            :return-value.sync="to">
        <v-text-field slot="activator"
                      label="По: "
                      v-model="to"
                      prepend-icon="mdi-calendar"
                      single-line
                      readonly></v-text-field>
        <v-date-picker locale="ru-ru" :first-day-of-week="1" v-model="to" @input="$refs.dateTo.save(to)"></v-date-picker>
    </v-menu>
    <v-select label="Выберите торговый автомат"
      :items="devices"
      item-text="name"
      item-value="id"
      v-model="selectedDeviceId"></v-select>
    <!-- <v-select label="Выберите статус заказа"
      :items="orderStates"
      item-text="text"
      item-value="value"
      v-model="selectedState"
      >
    </v-select> -->
    <v-btn color="info" @click="updateStats">Показать</v-btn>
    <v-btn color="info" @click="onReset">Очистить</v-btn>
  </v-layout>
  </v-container>
  <v-container
    fill-height
    fluid
    grid-list-xl
  >
  
    <v-layout wrap>
      
      
      <v-flex
        sm6
        xs12
        md6
        lg4
        v-if="orderStats"
      >
        <material-stats-card
          color="green"
          icon="mdi-cash"
          title="Оборот"
          :value="orderStats.totalSum / 100"
          small-value="₽"
          sub-icon="mdi-calendar"
          sub-text="За выбранный период"
        />
      </v-flex>
      <v-flex
        sm6
        xs12
        md6
        lg4
        v-if="orderStats"
      >
        <material-stats-card
          color="orange"
          icon="mdi-content-copy"
          title="Число товаров"
          :value="orderStats.totalCount"
          small-value=""
          sub-icon="mdi-calendar"
          sub-text="За выбранный период"
        />
      </v-flex>
      <v-flex
        sm6
        xs12
        md6
        lg4
        v-if="orderStats"
      >
        <material-stats-card
          color="red"
          icon="mdi-account"
          title="Уникальных покупателей"
          :value="orderStats.totalUsers"
          sub-icon="mdi-calendar"
          sub-text="За выбранный период"
        />
      </v-flex>

      <v-flex
        md12
      >
        <material-card
          color="green"
          title="Товары"
          text="Статистика по товарам"
        >
          <v-data-table
            :headers="headers"
            :items="items"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-success text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td>{{ getShopItemName(item.shopItemId) }}</td>
              <td>{{ item.amount }}</td>
              <td>{{ item.price / 100 }}</td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex>

      <!-- <v-flex
        md12
      >
        <material-card
          color="green"
          title="Товары в заказах"
          text="Статистика по товарам в заказах"
        >
          <v-data-table
            :headers="orderHeaders"
            :items="allShopItems"
            v-if="devices"
            hide-actions
          >
            <template
              slot="headerCell"
              slot-scope="{ header }"
            >
              <span
                class="subheading font-weight-light text-success text--darken-3"
                v-text="header.text"
              />
            </template>
            <template
              slot="items"
              slot-scope="{ item }"
            >
              <td>{{ item.date | date }} {{ item.date | time }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.amount }}</td>
              <td>{{ item.price }}</td>
              <td>{{ item.price * item.amount }}</td>
              <td>{{ getDeviceName(item.deviceId) }}</td>
              <td>{{ item.paymentOrderId }}</td>
            </template>
          </v-data-table>
        </material-card>
      </v-flex> -->
    </v-layout>
  </v-container>
</div>
</template>

<script>
export default {
  data () {
    return {
      dateFrom: null,
      from: null,
      dateTo: null,
      to: null,
      devices: [],
      selectedDeviceId: null,
      // selectedState: 0,
      orderStats: null,
      orderStates: [
        {text: 'Все', value: 0},
        {text: 'Не оплаченные', value: 1},
        {text: 'Оплаченные', value: 2},
        {text: 'Выданы', value: 3},
      ],
      dailySalesChart: {
        data: {
          labels: ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'],
          series: [
            
          ]
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0
          }),
          low: 0,
          high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        }
      },
      dataCompletedTasksChart: {
        data: {
          labels: ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'],
          series: [
            
          ]
        },
        options: {
          lineSmooth: this.$chartist.Interpolation.cardinal({
            tension: 0
          }),
          low: 0,
          high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0
          }
        }
      },
      emailsSubscriptionChart: {
        data: {
          labels: ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'],
          series: [
            

          ]
        },
        options: {
          axisX: {
            showGrid: false
          },
          low: 0,
          high: 1000,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0
          }
        },
        responsiveOptions: [
          ['screen and (max-width: 640px)', {
            seriesBarDistance: 5,
            axisX: {
              labelInterpolationFnc: function (value) {
                return value[0]
              }
            }
          }]
        ]
      },
      
    headers: [
      {
        sortable: true,
        text: 'Название',
        value: 'name'
      },
      {
        sortable: true,
        text: 'Количество',
        value: 'amount'
      },
      {
        sortable: true,
        text: 'Сумма, ₽',
        value: 'price',
        
      }
    ],
    items: [

    ],
    orderHeaders: [
      {
        sortable: true,
        text: 'Дата',
        value: 'date'
      },
      {
        sortable: true,
        text: 'Товар',
        value: 'name'
      },
      {
        sortable: true,
        text: 'Количество',
        value: 'amount'
      },
      {
        sortable: true,
        text: 'Цена, ₽',
        value: 'price',
        
      },
      {
        sortable: true,
        text: 'Сумма, ₽',
        value: 'price*amount',
        
      },
      {
        text: 'Торговый автомат',
        value: 'deviceId'
      },
      // {
      //   sortable: true,
      //   text: 'Клиент',
      //   value: 'username',
        
      // },
      // {
      //   sortable: true,
      //   text: 'Email',
      //   value: 'email',
        
      // },
      {
        sortable: true,
        text: 'Payment Id',
        value: 'paymentOrderId',
        
      }
      
    ],
    shopItems: [],
    allShopItems: [],
      tabs: 0,
      list: {
        0: false,
        1: false,
        2: false
      }
    }
  },
  mounted () {
    let today = new Date(Date.now());
    this.from = today.toISOString().substr(0, 10)
    today.setDate(today.getDate() + 1)
    this.to = today.toISOString().substr(0, 10)
    this.getStats()
    this.getShopItems()
    // this.getAllShopItems()
    this.getDevices()
  },
  // watch: {
  //   from: function (val) {
  //       this.updateStats();
  //   },
  //   to: function (val) {
  //       this.updateStats();
  //   },
  //   selectedDeviceId: function(val){
  //     this.updateStats();
  //   },
  //   selectedState: function(val){
  //     this.updateStats();
  //   }
  // },
  methods: {
    updateStats(){
      this.getStats()
      this.getShopItems()
      this.getShopItemsListStat()
      // this.getAllShopItems()
    },
    onReset() {
        let today = new Date(Date.now());
        this.from = today.toISOString().substr(0, 10)
        today.setDate(today.getDate() + 1)
        this.to = today.toISOString().substr(0, 10)
        this.selectedDeviceId = null;
        // this.selectedState = 0;
        this.updateStats();
    },
    getStats () {
      var options = {
          params: {
              from: this.from,
              to: this.to,
              deviceId: this.selectedDeviceId,
              // state: this.selectedState
          }
      };
      this.$http.get('/Statistics/events/total', options)
        .then(
          response => {
            this.orderStats = response.data
          }
        )
    },
    getShopItems(){
      this.$http.get('/shopItems')
        .then(response => {
          this.shopItems = response.data
        })
    },
    getShopItemsListStat(){
      var options = {
          params: {
              from: this.from,
              to: this.to,
              deviceId: this.selectedDeviceId,
              // state: this.selectedState
          }
      };
      this.$http.get('/Statistics/events/shopitems', options)
        .then(response => {
          this.items = response.data
        })
    },
    getDeviceName(deviceId){
      if (deviceId && this.devices)
        return this.devices.find(x=>x.id == deviceId).name;
      return deviceId;
    },
    getShopItemName(shopItemId){
      if (shopItemId && this.shopItems){
        const shopItem = this.shopItems.find(x=>x.id == shopItemId);
        if (shopItem)
          return shopItem.name
        return shopItemId;
      }
      return shopItemId;
    },
    // getAllShopItems(){
    //   var options = {
    //       params: {
    //           from: this.from,
    //           to: this.to,
    //           deviceId: this.selectedDeviceId,
    //           // state: this.selectedState
    //       }
    //   };
    //   this.$http.get('/Statistics/shopitems/all', options)
    //     .then(response => {
    //       this.allShopItems = response.data
    //     })
    // },
    complete (index) {
      this.list[index] = !this.list[index]
    },
    getDevices() {
      this.$http.get('/vendingmachines')
        .then(response => {
          this.devices = response.data
          this.devices.unshift({'id': null, name: 'Все'})
        })
    }
  }
}
</script>
